import SidebarNavigation from '../SidebarNavigation'
import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageMyPets = () => (
  <Template fullWidth>
    <Helmet>
      <title>My pets</title>
    </Helmet>
    <div className={style.pageMyPets}>
      <SidebarNavigation />
      <div className={style.petsContent}>
        <h1>My pets</h1>
        <div className={style.uploadContainer}>
          <div className={style.copy}>
            <h5>Add pets</h5>
            <p>
              You haven't added any pets yet. Get started by adding a photo of
              your pet
            </p>
          </div>
        </div>
        <div className={style.welcomeContainer}>
          {/* <div className={style.copy}>
            <h2>Welcome to Doggos</h2>
            <p>
              We're so excited to have you as a user and member of the
              community! To find dog sitting options that are best for your pet,
              create a free dog profile
            </p>
          </div>
          <div className={style.buttonContainer}>
            <button className={style.primary}>Add pet</button>
            <Link to={'/how-dog-profiles-work'} className={style.secondary}>
              Learn more
            </Link>
          </div> */}
          <div className={style.tipContainer}>
            <StaticImage
              src={'./doggos.png'}
              alt='Illustration of 2 cartoon-style yellow and purple dogs'
              className={style.image}
              placeholder='blurred'
            />
            <p>
              <strong>Tip</strong> To get help, type <span>@doggos</span> in any
              chat
            </p>
          </div>
        </div>

        {/* The form */}
        {/* <div className={style.container}>
          <div className={style.section}>
            <h2>Pet details</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='name'>
                  Pet name <span>*</span>
                </label>
                <input type='text' name='name'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='breed'>
                  Breed or breeds <span>*</span>
                </label>
                <input type='text' name='breed'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='age'>
                  Age <span>*</span>
                </label>
                <input type='text' name='age'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='color'>Coat color</label>
                <input type='text' name='color'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='routine'>
                  Describe your pets' daily routine, personality, or anything
                  else you think the sitter should know
                </label>
                <textarea rows='4' name='routine'></textarea>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <h2>Photos</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dogs face <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dog from the front <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
            </div>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='dog_face_photo'>
                  Upload a photo of your dog standing <span>*</span>
                </label>
                <input type='file' name='dog_face_photo'></input>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <h2>Food instructions</h2>
            <div className={style.formRow}>
              <div className={style.formGroup}>
                <label htmlFor='food_type'>
                  What kind of food does your dog eat?
                </label>
                <input type='text' name='food_type'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='meals_per_day'>Meals per day</label>
                <input type='number' name='meals_per_day'></input>
              </div>
              <div className={style.formGroup}>
                <label htmlFor='treats_allowed'>Treats allowed?</label>
                <input type='text' name='treats_allowed'></input>
              </div>
            </div>
            <div className={style.formGroup}>
              <label htmlFor='routine'>
                Describe your dogs food instructions (portion sizes, defrosting
                instructions, heating instructions, add water or broth, etc)
              </label>
              <textarea rows='4' name='routine'></textarea>
            </div>
          </div>
          <div className={style.section}>
            <h2>Additional details</h2>
          </div>
        </div> */}
      </div>
    </div>
  </Template>
)

export default PageMyPets
