// extracted by mini-css-extract-plugin
export var buttonContainer = "style-module--buttonContainer--1cba9";
export var container = "style-module--container--a3c8b";
export var copy = "style-module--copy--2ecec";
export var formGroup = "style-module--formGroup--7e0db";
export var formRow = "style-module--formRow--524a9";
export var image = "style-module--image--82b53";
export var pageMyPets = "style-module--pageMyPets--0fde3";
export var petsContent = "style-module--petsContent--a172a";
export var primary = "style-module--primary--ae96c";
export var secondary = "style-module--secondary--9452e";
export var section = "style-module--section--38909";
export var tipContainer = "style-module--tipContainer--c8bb3";
export var uploadContainer = "style-module--uploadContainer--9b855";
export var welcomeContainer = "style-module--welcomeContainer--a6f2c";